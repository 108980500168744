/* eslint-disable prettier/prettier */
import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { Box } from 'theme-ui'
import theme from '../../theme'

const Markdown = ({
  content,
  pFontSize,
  textColor,
  h1mt,
  h1mb,
  h2pt,
  h2pb,
  pmt,
  pmb,
}) => (
  <Box
    maxWidth="100vw"
    sx={{
      p: {
        variant: 'styles.p',
        fontSize: pFontSize || 'inherit',
        color: textColor || theme.text.color,
        mt: pmt || '',
        mb: pmb || '',
      },
      ul: { variant: 'styles.ul', color: textColor || theme.text.color },
      li: { variant: 'styles.li' },
      h1: {
        variant: 'styles.h1',
        color: textColor || theme.text.color,
        mt: h1mt || '',
        mb: h1mb || '',
      },
      h2: {
        variant: 'styles.h2',
        color: textColor || theme.text.color,
        pt: h2pt || [3, 3, 3, 4, 4],
        pb: h2pb || [3, 3, 3, 4, 4],
      },
      h3: {
        variant: 'styles.h3',
        color: textColor || theme.text.color,
      },
      h4: {
        variant: 'styles.h4',
        color: textColor || theme.text.color,
      },
      h6: {
        variant: 'styles.h6',
      },
      img: {
        maxWidth: '100vw',
        width: ['100%', '100%', '100%', '95%', '95%'],
        ml: 'auto',
        mr: 'auto',
        display: 'block',
      },
      a: {
        variant: 'styles.a',
      },
    }}
  >
    <ReactMarkdown
      source={content}
      escapeHtml={false}
      linkTarget="_blank"
    />
  </Box>
)

Markdown.propTypes = {
  content: PropTypes.string.isRequired,
  pFontSize: PropTypes.string,
  textColor: PropTypes.string,
  h1mt: PropTypes.array,
  h1mb: PropTypes.array,
  h2pt: PropTypes.array,
  h2pb: PropTypes.array,
  pmt: PropTypes.array,
  pmb: PropTypes.array,
}

export default Markdown
